:root {
  --menu-bg-color: #32333d;
  --button-bg-color: #5ba7d5;
}

.successmsg {
  background-color: lightgray;
  color: green;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.errdetail {
  color: firebrick;
  text-align: left;
  margin-bottom: 0rem;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.required {
  color: red;
  margin-left: 0.25rem;
  position: "left";
}

.instructions > svg {
  color: var(--button-bg-color);
  /*margin-right: 0.25rem;*/
}

.instructions > p {
  /*background-color: #5ba7d5;
  color: white;*/
  color: var(--button-bg-color);
}

.contenth > h3,
h6 {
  color: var(--button-bg-color);
}

.iconbuttonbg > svg {
  color: var(--button-bg-color);
}

.genericCol {
  color: var(--button-bg-color);
}

.genericBg {
  background-color: var(--button-bg-color);
}

.menubg {
  background-color: var(--menu-bg-color) !important;
}
